import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from 'components/Dialog';
import { closeAddSecUser } from 'reducers/DialogsReducer';
import { Button, DialogContentText } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import AddUser from './AddUser';
import defaultUserObj from './defaultUserObj';
import { createSearchUser, updateSearchUser } from 'Settings/Security/SecFunctions';
import { handleError } from 'reducers/ErrorReducer';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';

import { notify } from 'reducers/NotifierReducer';
import { processInputPhoneNo } from 'utils/functions';

const useStyles = makeStyles(theme => ({}));

const AddSecUserDialog = props => {
  const classes = useStyles();
  const [resendData, setResendData] = useState({});
  const [newUser, setNewUser] = useState(defaultUserObj);
  const { data } = props;

  useEffect(() => {
    if (data.selectedUser) {
      const selectedUserData = {
        UserId: data.selectedUser.ptsUserID,
        userName: data.selectedUser.Username,
        ptsProfileLookupId: data.selectedUser.ptsProfileID,
        ptsClearanceLevelId: data.selectedUser.ptsClearanceLevelID,
        ptsPersonId: data.selectedUser.ptsPersonID,
        email: data.selectedUser.PasswordEmail,
        defaultAgencyId: data.selectedUser.AgencyID,
        NCICUserName: data.selectedUser.NCICUsername,
        rid: data.selectedUser.RID,
        ori: data.selectedUser.ORI,
        isLockedOut: data.selectedUser.IsLockedOut,
        isDisable: data.selectedUser.IsDisabled,
        PhoneNumber: data.selectedUser.PhoneNumber || '',
        ApplicationName: data.selectedUser.ApplicationName,
        forcePWChange: false,
        NCICEmail: data.selectedUser.NCICEmail || '',
      };
      setResendData(selectedUserData);
      setNewUser(selectedUserData);
    }
  }, []);

  const close = () => {
    props.closeAddSecUser();
  };

  const save = async saveType => {
    if (saveType.type === 'confirmation' || saveType.type === 'username') {
      try {
        let needToUpdated = false;
        if (
          resendData.ApplicationName !== newUser.ApplicationName ||
          resendData.PhoneNumber !== newUser.PhoneNumber ||
          resendData.email !== newUser.email
        ) {
          needToUpdated = true;
        }
        const result = await updateSearchUser(
          newUser.UserId,
          { ...newUser, needToUpdated },
          saveType
        );
        // props.hideSpinner();
        props.notify('Credentials Successfully Resent', 'success');
      } catch (err) {
        props.notify('Could Not Send Credentials', 'error');
        console.log('err', err);
      }
    } else {
      if (validateUserData(newUser)) {
        if (newUser.UserId) {
          try {
            close();
            // props.showSpinner();
            await updateSearchUser(newUser.UserId, newUser);
            props.notify('User Updated', 'success');
          } catch (error) {
            console.log(error);
            props.handleError(error, 'Error Updating User');
          }
        } else {
          try {
            close();
            await createSearchUser(newUser);
            props.notify('User Created', 'success');
          } catch (error) {
            console.log(error);
            props.handleError(error, 'Error Creating User');
          }
        }
        await data.fetchUsers();
        data.setSelection(null);
      }
    }
  };

  const validateUserData = newUser => {
    if (!newUser.ApplicationName && !newUser.UserId) {
      props.notify('Default Application is required.', 'error');
      return false;
    }
    if (!newUser.userName) {
      props.notify('Username is required.', 'error');
      return false;
    }

    if (!newUser.ptsPersonId) {
      props.notify('Person field can not be empty.', 'error');
      return false;
    }
    if (!newUser.ptsProfileLookupId) {
      props.notify('Profile Lookup is required.', 'error');
      return false;
    }
    if (!newUser.defaultAgencyId) {
      props.notify('Default agency is required.', 'error');
      return false;
    }

    if (newUser.email) {
      const existedUser = (data?.userList || []).find(user => user.PasswordEmail === newUser.email);
      if (existedUser && newUser.email !== resendData.email) {
        props.notify(
          `Email address can't be used. This email is used for ${existedUser.Username}.`,
          'error'
        );
        return false;
      }
    } else {
      props.notify('Email is required.', 'error');
      return false;
    }
    return true;
  };

  const renderActions = () => {
    return (
      <>
        {data?.selectedUser && (
          <Button
            color="primary"
            variant="contained"
            autoFocus
            disabled={!newUser.email}
            onClick={() => save({ type: 'confirmation' })}>
            Confirmation Email
          </Button>
        )}
        <Button color="primary" variant="contained" autoFocus onClick={save}>
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  return (
    <Dialog
      toolbar
      onClose={close}
      title={newUser.UserId ? 'Update User' : 'Add User'}
      actions={renderActions()}
      maxWidth="md"
      fullWidth>
      <AddUser
        setNewUser={setNewUser}
        newUserData={newUser}
        selectedUser={data.selectedUser ? data.selectedUser : null}
        saveUser={save}
        resendData={resendData}
      />
      <DialogContentText style={{ marginTop: 10 }}>
        Note: * means the field's value is required
      </DialogContentText>
    </Dialog>
  );
};

const mapStateToProps = state => {
  return {
    dictionary: state.dictionary,
  };
};

export default connect(mapStateToProps, {
  hideSpinner,
  showSpinner,
  closeAddSecUser,
  handleError,
  notify,
})(AddSecUserDialog);
