const customIdentifiersSubmenu = [
  { type: 'link', label: 'Addresses', link: 'custom-addresses' },
  { type: 'link', label: 'Alerts', link: 'custom-alerts' },
  { type: 'link', label: 'Cases (Agency Spec.)', link: 'custom-cases' },
  { type: 'link', label: 'Items', link: 'custom-items' },
  { type: 'link', label: 'People', link: 'custom-people' },
  { type: 'link', label: 'Places', link: 'custom-places' },
  { type: 'link', label: 'Vehicles', link: 'custom-vehicles' },
  { type: 'link', label: 'Warrant', link: 'custom-warrant' },
];
const customIdentifiersSubmenuForCAD = [{ type: 'link', label: 'Events', link: 'custom-statuses' }];
const customIdentifiersSubmenuForRMS = [
  { type: 'link', label: 'Citation (Agency Spec.)', link: 'custom-citation' },
  { type: 'link', label: 'Evidence (Agency Spec.)', link: 'custom-evidence' },
  { type: 'link', label: 'Firearm', link: 'custom-firearm' },
  { type: 'link', label: 'Jewelry', link: 'custom-jewelry' },
  { type: 'link', label: 'Structure', link: 'custom-structure' },
];
const duplicateCheckingSubmenu = [
  { type: 'link', label: 'Events', link: 'events-duplicate-checking' },
  { type: 'link', label: 'Vehicles', link: 'vehicles-duplicate-checking' },
  { type: 'link', label: 'People', link: 'people-duplicate-checking' },
  { type: 'link', label: 'Addresses', link: 'addresses-duplicate-checking' },
  { type: 'link', label: 'Places', link: 'places-duplicate-checking' },
];

const securitySubmenu = [
  { type: 'link', label: 'Roles / Permissions', link: 'security-permissions' },

  { type: 'link', label: 'Profiles', link: 'search-profiles' },
  { type: 'link', label: 'Users', link: 'search-users' },
  { type: 'link', label: 'Users History', link: 'search-users-history' },
  { type: 'link', label: 'Clearance Levels', link: 'search-clearance-levels' },
];

const mobileSubmenu = [
  { type: 'link', label: 'General Information', link: 'mobile' },
  { type: 'link', label: 'NCIC Information', link: 'mobile-ncic' },

  {
    type: 'menu',
    label: 'Citation',
    submenu: [
      { type: 'link', label: 'Available Citations', link: 'citation-info' },
      { type: 'link', label: 'Citation Courts', link: 'citation-court' },
    ],
  },
];

const CADSubmenu = [
  { type: 'link', label: 'Automations', link: 'automation' },
  { type: 'link', label: 'CAD Lookup Codes', link: 'lookup-codes-cad' },
  { type: 'link', label: 'Global Lookup Codes', link: 'lookup-codes' },
  { type: 'menu', label: 'Custom Identifiers', submenu: customIdentifiersSubmenuForCAD },
  {
    type: 'link',
    label: 'Event Type (Event Type Initials & Icons)',
    link: 'event-type-initials',
  },
  { type: 'link', label: 'Dispatch Recommendations', link: 'resources' },
  { type: 'link', label: 'Notifications', link: 'notifications' },
  { type: 'link', label: 'Rip & Run', link: 'ripandrun' },
  { type: 'link', label: 'Rotation', link: 'rotation' },
  { type: 'link', label: 'SOPs', link: 'sops' },
  { type: 'link', label: 'E911 Setup', link: 'e911-setup' },
  // { type: 'link', label: 'Unit Inactivation Time', link: 'unit-inactivation-time' },
];

const RMSSubmenu = [
  { type: 'menu', label: 'Custom Identifiers', submenu: customIdentifiersSubmenuForRMS },
  { type: 'link', label: 'RMS Lookup Codes', link: 'lookup-codes-rms' },
  { type: 'link', label: 'Global Lookup Codes', link: 'lookup-codes' },
  { type: 'link', label: 'Workflows', link: 'workflows' },
];
const AgenciesSubMenues = [
  { type: 'link', label: 'Agencies', link: 'agencies' },
  // { type: 'link', label: 'Agency Type Icons', link: 'agency-type-icons' },
  // { type: 'link', label: 'Unit Selection', link: 'agency-unit-selection' },
];
const ApplicationSubmenu = [
  { type: 'link', label: 'Two Factor Auth', link: 'two-factor-auth' },
  { type: 'link', label: 'User Creation Mail', link: 'default-email-template' },
  { type: 'link', label: 'Appearance', link: 'appearance' },

  { type: 'menu', label: 'Agencies', submenu: AgenciesSubMenues },
  { type: 'menu', label: 'Custom Identifiers', submenu: customIdentifiersSubmenu },
  { type: 'menu', label: 'Duplicate Checking', submenu: duplicateCheckingSubmenu },
  { type: 'link', label: 'Global Lookup Codes', link: 'lookup-codes' },
  { type: 'link', label: 'Defaults', link: 'defaults' },
  { type: 'link', label: 'Mapping', link: 'geofence-designer' },
];
const ConfigurationSubmenu = [
  { type: 'link', label: 'NIBRS Lookup Codes', link: 'lookup-codes-ibr' },
  { type: 'link', label: 'Notifications Settings', link: 'notification-settings' },
  { type: 'link', label: 'PTS Help/Training Academy links', link: 'essential-links' },
  { type: 'link', label: 'NIBRS Offence Codes', link: 'nibrs-offense-codes' },
];
const menu = [
  { type: 'menu', label: 'Application', submenu: ApplicationSubmenu },
  { type: 'menu', label: 'Security', submenu: securitySubmenu },
  { type: 'menu', label: 'CAD', submenu: CADSubmenu },
  { type: 'menu', label: 'Mobile', submenu: mobileSubmenu },
  { type: 'menu', label: 'RMS', submenu: RMSSubmenu },
  { type: 'menu', label: 'Configurations', submenu: ConfigurationSubmenu },
];

export default menu;
