const ADD_PAGE = 'PAGING/ADD_PAGE';
const UNIT_STATUS = 'UNIT_STATUS';
const ADD_EVENT = 'ADD_EVENT';
const EDIT_EVENT = 'EDIT_EVENT';
const ADD_SOP = 'ADD_SOP';
const EDIT_SOP = 'EDIT_SOP';
const EDIT_PAGE_GROUP = 'EDIT_PAGE_GROUP';
const EDIT_PAGE_RECIPIENT = 'EDIT_PAGE_RECIPIENT';
const EDIT_PAGE_TEMPLATE = 'EDIT_PAGE_TEMPLATE';
const IMPORT_ESNS = 'IMPORT_ESNS';
const IMPORT_ZONES = 'IMPORT_ZONES';
const DISPATCH_RECOMMENDATIONS = 'DISPATCH_RECOMMENDATIONS';
const UNIT_PROPS = 'UNIT_PROPS';
const ADD_NOTE = 'ADD_NOTE';
const EVENT_DETAIL = 'EVENT_DETAIL';
const NEW_UNIT_STATUS = 'NEW_UNIT_STATUS';
const UNIT_ZONE = 'UNIT_ZONE';
const ADD_SEC_USER = 'ADD_SEC_USER';
const REMOVE_SEC_USER = 'REMOVE_SEC_USER';
const OPTIONS_DIALOG = 'OPTIONS_DIALOG';
const ADD_EDIT_CLEARANCE_LEVEL = 'ADD_EDIT_CLEARANCE_LEVEL';

const ADD_SEC_ROLE = 'ADD_SEC_ROLE';
const ADD_SEC_PROFILE = 'ADD_SEC_PROFILE';
const EDIT_SEC_ROLE = 'EDIT_SEC_ROLE';
const EDIT_SEC_PROFILE = 'EDIT_SEC_PROFILE';
const ADD_CITATION_FILE = 'ADD_CITATION_FILE';
const ADD_CITATION_COURT = 'ADD_CITATION_COURT';
const ADD_ROTATION = 'ADD_ROTATION';
const ADD_AGENCY_UNIT_SELECTION = 'ADD_AGENCY_UNIT_SELECTION';
const EDIT_AGENCY = 'EDIT_AGENCY';
const DELETE_AGENCY = 'DELETE_AGENCY';
const EDIT_CONTACT_METHOD = 'EDIT_CONTACT_METHOD';
const EDIT_PLACE_EMPLOYMENT = 'EDIT_PLACE_EMPLOYMENT';
const EDIT_VEHICLE_REG = 'EDIT_VEHICLE_REG';
const EDIT_PLACE_ADDRESS = 'EDIT_PLACE_ADDRESS';
const ADD_EDIT_EVENT_TYPE_INITIALS = 'ADD_EDIT_EVENT_TYPE_INITIAL';
const ADD_EDIT_DEFAULT_EMAILS = 'ADD_EDIT_DEFAULT_EMAILS';
const ADD_EVENT_SUB_TYPES = 'ADD_EVENT_SUB_TYPES';
const MERGE_CITIES = 'MERGE_CITIES';
const SHOW_NIBRS_OFFENCE = 'SHOW_NIBRS_OFFENCE';
const defaultDialogs = {
  editPageGroup: null,
  editPageRecipient: null,
  editPageTemplate: null,
  importEsns: null,
  importZones: null,
  addEvent: null,
  editEvent: null,
  addOutserviceUnit: null,
  addSop: null,
  editSop: null,
  dispRecommendation: null,
  unitProps: null,
  addNote: null,
  eventDetail: null,
  newUnitStatus: null,
  unitZone: null,
  addSecUser: null,
  removeSecUser: null,
  optionsDialog: null,
  addEditClearanceLevel: null,
  addSecRole: null,
  addSecProfile: null,
  editSecRole: null,
  editSecProfile: null,
  addCitationFile: null,
  addCitationCourt: null,
  addAgencyUnitSelection: null,
  editAgency: null,
  deleteAgency: null,
  editContactMethod: null,
  placeEmployment: null,
  editVehicleReg: null,
  editPlaceAddress: null,
  addEditEventTypeInitials: null,
  defaultEmails: null,
  addEventSubTypes: null,
  mergeCities: null,
  editNibrsOffence: null,
  addRotation: null,
};

export const SET_ADDOUTSERVICEUNIT = 'SET_ADDOUTSERVICEUNIT';

export const addOutserviceUnitDialog = (show = true) => dispatch =>
  dispatch({ type: SET_ADDOUTSERVICEUNIT, show });

export const addPageDialog = data => dispatch =>
  dispatch({ type: ADD_PAGE, data: data ? data : {} });
export const closeAddPageDialog = () => dispatch => dispatch({ type: ADD_PAGE, data: null });

export const addEvent = () => dispatch => dispatch({ type: ADD_EVENT, data: true });
export const closeAddEvent = () => dispatch => dispatch({ type: ADD_EVENT, data: null });

export const editEvent = EventID => dispatch => dispatch({ type: EDIT_EVENT, data: EventID });
export const closeEditEvent = () => dispatch => dispatch({ type: EDIT_EVENT, data: null });

export const addSop = ptsParentID => dispatch =>
  dispatch({ type: ADD_SOP, data: { ptsSOPID: true, ptsParentID } });
export const closeAddSop = () => dispatch => dispatch({ type: ADD_SOP, data: null });

export const editSop = data => dispatch => dispatch({ type: EDIT_SOP, data: { ptsSOPID: data } });
export const closeEditSop = () => dispatch => dispatch({ type: EDIT_SOP, data: null });

export const editPageGroup = data => dispatch => dispatch({ type: EDIT_PAGE_GROUP, data });
export const closeEditPageGroup = () => dispatch => dispatch({ type: EDIT_PAGE_GROUP, data: null });

export const editPageRecipient = data => dispatch => dispatch({ type: EDIT_PAGE_RECIPIENT, data });
export const closeEditPageRecipient = () => dispatch =>
  dispatch({ type: EDIT_PAGE_RECIPIENT, data: null });

export const editPageTemplate = ROWGUID => dispatch =>
  dispatch({ type: EDIT_PAGE_TEMPLATE, data: ROWGUID });
export const closeEditPageTemplate = () => dispatch =>
  dispatch({ type: EDIT_PAGE_TEMPLATE, data: null });

export const importEsns = (data = true) => dispatch => dispatch({ type: IMPORT_ESNS, data });
export const closeImportEsns = () => dispatch => dispatch({ type: IMPORT_ESNS, data: null });

export const importZones = (data = true) => dispatch => dispatch({ type: IMPORT_ZONES, data });
export const closeImportZones = () => dispatch => dispatch({ type: IMPORT_ZONES, data: null });

export const dispRecommendations = ptsEventID => dispatch =>
  dispatch({ type: DISPATCH_RECOMMENDATIONS, ptsEventID });
export const closeDispRecommendations = () => dispatch =>
  dispatch({ type: DISPATCH_RECOMMENDATIONS, ptsEventID: null });

export const showUnitProps = ptsUnitID => dispatch => dispatch({ type: UNIT_PROPS, ptsUnitID });
export const closeUnitProps = () => dispatch => dispatch({ type: UNIT_PROPS, ptsUnitID: null });

export const showAddNoteDialog = ptsEventID => dispatch => dispatch({ type: ADD_NOTE, ptsEventID });
export const closeAddNoteDialog = () => dispatch => dispatch({ type: ADD_NOTE, ptsEventID: null });

export const showEventDetails = ptsEventID => dispatch =>
  dispatch({ type: EVENT_DETAIL, ptsEventID });
export const closeEventDetails = () => dispatch =>
  dispatch({ type: EVENT_DETAIL, ptsEventID: null });

export const newUnitStatus = ptsUnitID => dispatch =>
  dispatch({ type: NEW_UNIT_STATUS, ptsUnitID });
export const closeNewUnitStatus = () => dispatch =>
  dispatch({ type: NEW_UNIT_STATUS, ptsUnitID: null });

export const setUnitZone = ptsUnitID => dispatch => dispatch({ type: UNIT_ZONE, ptsUnitID });
export const closeSetUnitZone = () => dispatch => dispatch({ type: UNIT_ZONE, ptsUnitID: null });

export const showAddSecUser = (data = {}) => dispatch => dispatch({ type: ADD_SEC_USER, data });
export const closeAddSecUser = () => dispatch => dispatch({ type: ADD_SEC_USER, data: null });

export const showRemoveSecUser = (data = {}) => dispatch =>
  dispatch({ type: REMOVE_SEC_USER, data });
export const closeRemoveSecUser = () => dispatch => dispatch({ type: REMOVE_SEC_USER, data: null });

export const showOptionsDialog = (data = {}) => dispatch =>
  dispatch({ type: OPTIONS_DIALOG, data });
export const closeOptionsDialog = () => dispatch => dispatch({ type: OPTIONS_DIALOG, data: null });

export const showAddEditClearanceLevel = (data = {}) => dispatch => {
  dispatch({ type: ADD_EDIT_CLEARANCE_LEVEL, data });
};
export const closeAddEditClearanceLevel = () => dispatch =>
  dispatch({ type: ADD_EDIT_CLEARANCE_LEVEL, data: null });

export const showAddSecRole = (data = {}) => dispatch => dispatch({ type: ADD_SEC_ROLE, data });
export const closeAddSecRole = () => dispatch => dispatch({ type: ADD_SEC_ROLE, data: null });

export const showEditSecRole = (data = {}) => dispatch => dispatch({ type: EDIT_SEC_ROLE, data });
export const closeEditSecRole = () => dispatch => dispatch({ type: EDIT_SEC_ROLE, data: null });

export const showAddSecProfile = (data = {}) => dispatch =>
  dispatch({ type: ADD_SEC_PROFILE, data });
export const closeAddSecProfile = () => dispatch => dispatch({ type: ADD_SEC_PROFILE, data: null });

export const showEditSecProfile = (data = {}) => dispatch =>
  dispatch({ type: EDIT_SEC_PROFILE, data });
export const closeEditSecProfile = () => dispatch =>
  dispatch({ type: EDIT_SEC_PROFILE, data: null });
export const showCitationFile = (data = {}) => dispatch =>
  dispatch({ type: ADD_CITATION_FILE, data: data });
export const closeCitationFile = (data = {}) => dispatch =>
  dispatch({ type: ADD_CITATION_FILE, data: null });
export const showRotationSeup = (data = {}) => dispatch =>
  dispatch({ type: ADD_ROTATION, data: data });
export const closeRotationSeup = (data = {}) => dispatch =>
  dispatch({ type: ADD_ROTATION, data: null });

export const showCitationCourt = (data = {}) => dispatch =>
  dispatch({ type: ADD_CITATION_COURT, data: data });
export const closeCitationCourt = (data = {}) => dispatch =>
  dispatch({ type: ADD_CITATION_COURT, data: null });
export const showAgencyBasedUnitSelection = (data = {}) => dispatch =>
  dispatch({ type: ADD_AGENCY_UNIT_SELECTION, data: data });
export const closeAgencyBasedUnitSelection = (data = {}) => dispatch =>
  dispatch({ type: ADD_AGENCY_UNIT_SELECTION, data: null });

export const showEditAgency = (data = {}) => dispatch => dispatch({ type: EDIT_AGENCY, data });
export const closeEditAgency = () => dispatch => dispatch({ type: EDIT_AGENCY, data: null });
export const showDeleteAgencyDialog = (data = {}) => dispatch =>
  dispatch({ type: DELETE_AGENCY, data });
export const closeDeleteAgencyDialog = () => dispatch =>
  dispatch({ type: DELETE_AGENCY, data: null });

export const showContactMethod = data => dispatch => dispatch({ type: EDIT_CONTACT_METHOD, data });
export const closeContactMethod = data => dispatch =>
  dispatch({ type: EDIT_CONTACT_METHOD, data: null });

export const showPlaceEmployment = data => dispatch =>
  dispatch({ type: EDIT_PLACE_EMPLOYMENT, data });
export const closePlaceEmployment = data => dispatch =>
  dispatch({ type: EDIT_PLACE_EMPLOYMENT, data: null });

export const showVehicleEditReg = data => dispatch => dispatch({ type: EDIT_VEHICLE_REG, data });
export const closeVehicleEditReg = data => dispatch =>
  dispatch({ type: EDIT_VEHICLE_REG, data: null });

export const showPlaceAddressEdit = data => dispatch =>
  dispatch({ type: EDIT_PLACE_ADDRESS, data });
export const closePlaceAddressEdit = data => dispatch =>
  dispatch({ type: EDIT_PLACE_ADDRESS, data: null });
export const showAddEditEventTypeInitials = (data = {}) => dispatch => {
  dispatch({ type: ADD_EDIT_EVENT_TYPE_INITIALS, data });
};
export const closeAddEditEventTypeInitials = () => dispatch =>
  dispatch({ type: ADD_EDIT_EVENT_TYPE_INITIALS, data: null });
export const showAddEventSubTypes = data => dispatch => {
  dispatch({ type: ADD_EVENT_SUB_TYPES, data });
};
export const closeAddEventSubTypes = () => dispatch => {
  dispatch({ type: ADD_EVENT_SUB_TYPES, data: null });
};
export const showAddEditDefaultEmails = (data = '') => dispatch => {
  dispatch({ type: ADD_EDIT_DEFAULT_EMAILS, data });
};
export const closeAddEditDefaultEmails = () => dispatch =>
  dispatch({ type: ADD_EDIT_DEFAULT_EMAILS, data: null });
export const showMergeCities = data => dispatch => dispatch({ type: MERGE_CITIES, data: data });
export const closeMergeCities = () => dispatch => dispatch({ type: MERGE_CITIES, data: null });
export const showNIBRSOffence = data => dispatch =>
  dispatch({ type: SHOW_NIBRS_OFFENCE, data: data });
export const closeNIBRSOffence = () => dispatch =>
  dispatch({ type: SHOW_NIBRS_OFFENCE, data: null });
export default function reducer(state = defaultDialogs, action) {
  switch (action.type) {
    case SET_ADDOUTSERVICEUNIT:
      state = { ...state, addOutserviceUnit: action.show };
      break;
    case ADD_PAGE:
      return { ...state, addPage: action.data };
    case UNIT_STATUS:
      return { ...state, unitStatus: action.data };
    case ADD_EVENT:
      return { ...state, addEvent: action.data };
    case EDIT_EVENT:
      return { ...state, editEvent: action.data };
    case ADD_SOP:
      return { ...state, editSop: action.data };
    case EDIT_SOP:
      return { ...state, editSop: action.data };
    case EDIT_PAGE_GROUP:
      return { ...state, editPageGroup: action.data };
    case EDIT_PAGE_RECIPIENT:
      return { ...state, editPageRecipient: action.data };
    case EDIT_PAGE_TEMPLATE:
      return { ...state, editPageTemplate: action.data };
    case IMPORT_ESNS:
      return { ...state, importEsns: action.data };
    case IMPORT_ZONES:
      return { ...state, importZones: action.data };
    case DISPATCH_RECOMMENDATIONS:
      return { ...state, dispRecommendation: action.ptsEventID };
    case UNIT_PROPS:
      return { ...state, unitProps: action.ptsUnitID };
    case ADD_NOTE:
      return { ...state, addNote: action.ptsEventID };
    case EVENT_DETAIL:
      return { ...state, eventDetail: action.ptsEventID };
    case NEW_UNIT_STATUS:
      return { ...state, newUnitStatus: action.ptsUnitID };
    case UNIT_ZONE:
      return { ...state, unitZone: action.ptsUnitID };
    case ADD_SEC_USER:
      return { ...state, addSecUser: action.data };
    case REMOVE_SEC_USER:
      return { ...state, removeSecUser: action.data };
    case OPTIONS_DIALOG:
      return { ...state, optionsDialog: action.data };
    case ADD_EDIT_CLEARANCE_LEVEL:
      return { ...state, addEditClearanceLevel: action.data };
    case ADD_SEC_ROLE:
      return { ...state, addSecRole: action.data };
    case EDIT_SEC_ROLE:
      return { ...state, editSecRole: action.data };
    case ADD_SEC_PROFILE:
      return { ...state, addSecProfile: action.data };
    case EDIT_SEC_PROFILE:
      return { ...state, editSecProfile: action.data };
    case EDIT_AGENCY:
      return { ...state, editAgency: action.data };
    case DELETE_AGENCY:
      return {
        ...state,
        deleteAgency: action.data,
      };
    case EDIT_CONTACT_METHOD:
      return {
        ...state,
        editContactMethod: action.data,
      };
    case ADD_CITATION_FILE:
      return {
        ...state,
        addCitationFile: action.data,
      };
    case ADD_CITATION_COURT:
      return {
        ...state,
        addCitationCourt: action.data,
      };
    case ADD_ROTATION:
      return {
        ...state,
        addRotation: action.data,
      };
    case ADD_AGENCY_UNIT_SELECTION:
      return {
        ...state,
        addAgencyUnitSelection: action.data,
      };
    case EDIT_PLACE_EMPLOYMENT:
      return { ...state, placeEmployment: action.data };
    case EDIT_VEHICLE_REG:
      return { ...state, editVehicleReg: action.data };
    case EDIT_PLACE_ADDRESS:
      return { ...state, editPlaceAddress: action.data };
    case ADD_EDIT_EVENT_TYPE_INITIALS:
      return { ...state, addEditEventTypeInitials: action.data };
    case ADD_EDIT_DEFAULT_EMAILS:
      return { ...state, defaultEmails: action.data };
    case ADD_EVENT_SUB_TYPES:
      return { ...state, addEventSubTypes: action.data };
    case MERGE_CITIES:
      return { ...state, mergeCities: action.data };
    case SHOW_NIBRS_OFFENCE:
      return {
        ...state,
        editNibrsOffence: action.data,
      };
    default:
      break;
  }
  return state;
}
