import { Box, Button, Checkbox, FormControlLabel, TextField, makeStyles } from '@material-ui/core';
import FormDivider from 'components/FormDivider';
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import formStyles, { ColCard, Fills, Row, RowInner } from 'utils/formStyles';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import { handleError } from 'reducers/ErrorReducer';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import { notify } from 'reducers/NotifierReducer';
import { getService } from 'reducers/service';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
const useStyles = makeStyles(theme => ({
  ...formStyles,
  wrap: {
    padding: theme.spacing(3),
  },
  textField: { marginTop: '15px' },
  toggleItem: {
    width: '100%',
  },
}));

export const AgencyMobile = props => {
  const classes = useStyles();
  const { wsClient, agencyId } = props;
  const service = getService('unit-selection-mobile');
  const userName = useSelector(state => state.user.userData.user.Username);
  const [isUnitSelectionEnable, setIsUnitSelectionEnable] = useState(false);
  const [isResourceEditEnable, setIsEditResourceEnable] = useState(false);
  const [exist, setIsExist] = useState(false);
  const [editResourceValue, setEditResourceValue] = useState('both');
  const [editResourceSettingsExist, setEditResourceSettingsExist] = useState(false);
  useEffect(() => {
    if (wsClient.websocket) {
      search();
    }
  }, [wsClient]);
  const search = async () => {
    service.timeout = 20000;
    try {
      const data = await service.find({
        query: {
          $or: [{ Path: 'AgencyBasedUnitSetting' }, { Path: 'EditResourceOnLoginSettings' }],
          CDRKey: agencyId,
        },
      });

      if (data.length > 0) {
        const unitSelectedSettings = data.find(item => item.Path === 'AgencyBasedUnitSetting');
        if (unitSelectedSettings) {
          setIsExist(true);
          setIsUnitSelectionEnable(unitSelectedSettings.ValueBoolean);
        }

        const editResourceSettings = data.find(item => item.Path === 'EditResourceOnLoginSettings');

        if (editResourceSettings) {
          setEditResourceSettingsExist(true);
          const value = editResourceSettings.ValueString;

          if (value === 'both' || value === 'person' || value === 'vehicle') {
            setIsEditResourceEnable(true);
            setEditResourceValue(value);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const save = async () => {
    props.showSpinner();
    try {
      const result = await Promise.all(queryBuilder());
      props.notify('Settings Saved');
    } catch (err) {
      props.handleError(err, 'Error, cannot not saved.', err);
    }
    props.hideSpinner();
  };

  const queryBuilder = () => {
    const resourceEditState = isResourceEditEnable ? editResourceValue : null;
    const queries = [];
    if (exist) {
      queries.push(
        service.patch(
          null,
          {
            ValueBoolean: isUnitSelectionEnable,
            UpdatedBy: userName,
          },
          {
            query: {
              CDRKey: agencyId,
              Path: 'AgencyBasedUnitSetting',
            },
          }
        )
      );
    } else {
      queries.push(
        service.create({
          CDRKey: agencyId,
          ValueBoolean: isUnitSelectionEnable,
          Path: 'AgencyBasedUnitSetting',
          CreatedBy: userName,
          UpdatedBy: userName,
        })
      );
    }
    if (editResourceSettingsExist) {
      queries.push(
        service.patch(
          null,
          {
            ValueString: resourceEditState,
            UpdatedBy: userName,
          },
          {
            query: {
              CDRKey: agencyId,
              Path: 'EditResourceOnLoginSettings',
            },
          }
        )
      );
    } else {
      queries.push(
        service.create({
          CDRKey: agencyId,
          ValueString: resourceEditState,
          Path: 'EditResourceOnLoginSettings',
          CreatedBy: userName,
          UpdatedBy: userName,
        })
      );
    }

    return queries;
  };

  const renderActions = () => {
    return (
      <div className="text-right pb-3">
        <Button color="primary" variant="contained" autoFocus onClick={save}>
          <SaveIcon className="mr-2" /> Save
        </Button>
      </div>
    );
  };
  return (
    <div className={classes.wrap}>
      {renderActions()}
      <Row>
        <ColCard minWidth={500}>
          <FormDivider title="Cad Mobile Settings" />
          <RowInner>
            <TextField
              id="agency"
              label="Agency ID"
              variant="outlined"
              value={agencyId || ''}
              size="small"
              disabled
              className="mr-2"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isUnitSelectionEnable}
                  onChange={e => {
                    setIsUnitSelectionEnable(e.target.checked);
                  }}
                />
              }
              label="Select Unit On Mobile Login"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isResourceEditEnable}
                  onChange={e => {
                    setIsEditResourceEnable(e.target.checked);
                  }}
                />
              }
              label="Edit Unit Resource On Mobile Login"
            />
            {isResourceEditEnable && (
              <Box style={{ marginLeft: 10 }}>
                <ToggleButtonGroup
                  value={editResourceValue}
                  exclusive
                  size="small"
                  disabled={true}
                  style={{ minWidth: '350px' }}
                  onChange={(e, newValue) => {
                    setEditResourceValue(newValue);
                  }}
                  aria-label="User Status">
                  <ToggleButton value="person" aria-label="person" className={classes.toggleItem}>
                    Person
                  </ToggleButton>
                  <ToggleButton
                    value="both"
                    aria-label="both"
                    fullWidth
                    className={classes.toggleItem}>
                    Both
                  </ToggleButton>
                  <ToggleButton
                    value="vehicle"
                    aria-label="vehicle"
                    fullWidth
                    className={classes.toggleItem}>
                    vehicle
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
            )}

            <Fills className={classes.item} />
          </RowInner>
        </ColCard>
      </Row>
    </div>
  );
};

const mapStateToProps = state => ({
  wsClient: state.websocket,
});

const mapDispatchToProps = {
  showSpinner,
  hideSpinner,
  handleError,
  notify,
};

export default connect(mapStateToProps, mapDispatchToProps)(AgencyMobile);
