import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router';

import { Grid, Typography } from '@material-ui/core';
import { XGrid } from '@material-ui/x-grid';

import Content from '../Content';
import AddButton from './AddButton';
import EditButton from './EditButton';
import DeleteButton from './DeleteButton';
import AddEditDialog from './AddEditDialog';

import { getService } from '../../reducers/service';
import { findSearchGroups } from 'Settings/Security/SecFunctions';

const initialsColumns = [
  { field: 'ptsWFlowID', headerName: 'ptsWFlowID', width: 250 },
  { field: 'Name', headerName: 'Name', width: 250 },
  { field: 'DefaultForIncident', headerName: 'Is default', width: 200 },
  { field: 'AgencyID', headerName: 'Agency', width: 250 },
];

const WorkFlows = () => {
  const history = useHistory();
  const service = getService('ptswflows');
  const workflowStepService = getService('ptswflowsteps');
  const agencyService = getService('ptsagencies');
  const [workflows, setWorkFlows] = useState([]);
  const [selected, setSelected] = useState({});
  const [workflowDialog, setWorkflowDialog] = useState(false);
  const [dialogType, setDialogType] = useState('');
  const [reload, setReload] = useState(false);
  const [fetchedSteps, setFetchedSteps] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reloadOrNot, setReloadOrNot] = useState(true);
  const [roles, setRoles] = useState([]);
  const [agencies, setAgencies] = useState([]);
  const xgridRef = useRef();

  useEffect(() => {
    const xgrid = xgridRef.current;
    const viewportHeight = window.innerHeight;
    const xgridOffsetTop = xgrid.offsetTop;
    const xgridHeight = viewportHeight - xgridOffsetTop - 30;
    xgrid.style.height = `${xgridHeight}px`;
  }, []);
  const handleOnClickAddPage = () => {
    // history.push('/workflows/add');
    setSelected({});
    setWorkflowDialog(true);
    setDialogType('Add');
  };

  const handleOnClickEditPage = () => {
    // history.push(`/workflows/edit/${selected.ptsWorkflowID}`);
    setWorkflowDialog(true);
    setDialogType('Edit');
  };

  const handleOnClickDelete = () => {
    let id = selected?.ptsWFlowID;
    console.log('ID TO DELETE: ', id);

    service
      .remove(id)
      .then(res => {
        console.log('DELETED WORKFLOW: ', res);
        setReloadOrNot(true);
        setReload(true);
        setWorkFlows([]);
      })
      .catch(err => console.log(err));
  };

  const closeWorkflowDialog = state => {
    setWorkflowDialog(false);
    setReload(true);
    setWorkFlows([]);

    if (state === 'reload') setReloadOrNot(true);
    else if (state === 'notReload') setReloadOrNot(false);
  };

  useEffect(() => {
    service
      .find({
        query: {
          IsDeleted: false,
          $limit: 0,
        },
      })
      .then(res => {
        if (reloadOrNot === true) setLoading(true);
        let d = res.data.map((r, i) => {
          return { ...r, id: i + 1 };
        });

        workflowStepService
          .find({
            query: {
              IsDeleted: false,
              $limit: 0,
            },
          })
          .then(res => {
            console.log('FETCHED: ', res);
            setFetchedSteps(res);
          })
          .catch(err => console.log(err));

        if (reloadOrNot === true) {
          setTimeout(() => {
            setLoading(false);
            setWorkFlows(d);
            setReloadOrNot(false);
          }, 500);
        } else {
          setWorkFlows(d);
          setReloadOrNot(false);
        }

        console.log('WORKFLOW DATAS: ', res);
        setReload(false);
      })
      .catch(err => console.log(err));
  }, [service, reload]);

  useEffect(() => {
    findSearchGroups()
      .then(res => {
        let roleData = res.map((role, index) => {
          return { roleName: role.Label, RoleID: role.ptsGroupID };
        });
        setRoles(roleData);
      })
      .catch(err => console.log(err));
  }, [reload]);

  useEffect(() => {
    agencyService
      .find({
        query: {
          $select: ['AgencyID', 'AgencyType'],
          IsDeleted: false,
        },
      })
      .then(res => {
        console.log('AGENCIES: ', res.data);
        setAgencies(res.data);
      });
  }, [agencyService, reload]);
  // console.log(xgridRef?.current.offsetTops);
  console.log('selected: ', selected);
  return (
    <Content>
      <Grid container className="p-4">
        <Grid xs={6} item>
          <Typography variant="h4">Workflows</Typography>
        </Grid>

        <Grid container justify="flex-end" xs={6} item>
          <Grid item className="pr-3">
            <AddButton tooltipText="Add Workflow" onClick={handleOnClickAddPage} />
          </Grid>
          {selected.ptsWFlowID && (
            <>
              <Grid item className="pr-3">
                <EditButton tooltipText="Edit Workflow" onClick={handleOnClickEditPage} />
              </Grid>
              <Grid item className="pr-3">
                <DeleteButton tooltipText="Delete Workflow" onClick={handleOnClickDelete} />
              </Grid>
            </>
          )}
        </Grid>

        <Grid
          ref={xgridRef}
          xs={12}
          item
          className="mt-4 "
          style={{
            boxSizing: 'border-box',

            width: '100%',
          }}>
          <XGrid
            columns={initialsColumns}
            rows={workflows}
            rowHeight={38}
            disableMultipleSelection
            onRowClick={row => setSelected(row.row)}
            loading={loading}
          />
        </Grid>
      </Grid>
      {workflowDialog === true && (
        <AddEditDialog
          workflowDialog={workflowDialog}
          closeWorkflowDialog={closeWorkflowDialog}
          workFlowDialogType={dialogType}
          selectedWorkflow={selected}
          allWorkflows={workflows}
          fetchedSteps={fetchedSteps}
          allRoles={roles}
          agencies={agencies}
        />
      )}
    </Content>
  );
};

export default WorkFlows;
