import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Content from 'Settings/Content';
import { findSearchGroups } from '../SecFunctions';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Applications from './ApplicationPermissions';
import Codes from './CodePermissions';
import CreateNewPermissions from './CreateNewPermissions';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import Fab from '@material-ui/core/Fab';
import { showAddSecRole, showEditSecRole } from 'reducers/DialogsReducer';
import Tooltip from 'components/Tooltip';
// import DefaultPermissions from './DefaultPermissions';
import PermissionsComponent from './PermissionsComponent';
import AddPermission from './AddPermission';

const useStyles = makeStyles(theme => ({
  autoComplete: {
    maxWidth: 300,
    marginTop: '10px',
  },
  tabs: {
    width: '100%',
    '& button': {
      '& > span': {
        textTransform: 'none',
      },
    },
  },
  settings: {
    height: '100%',
    overflowY: 'auto',
  },
  actions: {
    textAlign: 'right',
    marginRight: '10px',
  },
  msg: {
    marginTop: 5,
    color: '#ff1744',
  },
}));

function Permissions(props) {
  const classes = useStyles();
  const { dataUpdate } = props;
  const [allGroups, setAllGroups] = useState([]);
  const [group, setGroup] = useState(null);
  const [tabValue, setTabValue] = useState('applications');
  const [showAddPermission, setShowAddPermission] = useState(false);
  const addActive = tabValue !== 'create' && tabValue !== 'code';
  const [roleAgencyID, setRoleAgencyID] = useState('');
  useEffect(() => {
    fetchGroups();
  }, []);

  useEffect(() => {
    if (dataUpdate && (dataUpdate.type === 'add-role' || dataUpdate.type === 'edit-role'))
      fetchGroups();
  }, [dataUpdate]);

  const fetchGroups = async () => {
    const result = await findSearchGroups();
    setAllGroups(result);
  };

  const addSecRole = () => props.showAddSecRole();

  const editSecRole = () => props.showEditSecRole(group.ptsGroupID);

  const renderRoles = () => {
    const handleChange = (e, newValue) => {
      console.log('newValue', newValue);
      setRoleAgencyID(newValue?.AgencyID || '');
      setGroup(newValue);
    };
    return (
      <div style={{ display: 'flex', gap: '40px', width: '100%' }}>
        <Autocomplete
          disablePortal
          options={allGroups}
          style={{ minWidth: '300px' }}
          className={classes.autoComplete}
          renderInput={params => <TextField {...params} label="Roles" variant="outlined" />}
          onChange={handleChange}
          getOptionLabel={option => option.Label}
          renderOption={option => `${option.Label} ${option.Type ? ' - ' + option.Type : ''}`}
          getOptionSelected={(option, value) => option.ptsGroupID === value.ptsGroupID}
          size="small"
          value={group}
        />
        {roleAgencyID && (
          <p className="mt-4">
            Agency ID: <strong>{roleAgencyID}</strong>
          </p>
        )}
      </div>
    );
  };

  const renderTabs = () => {
    const handleTabChange = (event, newValue) => setTabValue(newValue);
    return (
      <div className={classes.settings}>
        <Tabs
          variant="scrollable"
          value={tabValue}
          onChange={handleTabChange}
          className={classes.tabs}>
          <Tab label="Applications" value="applications" />
          <Tab label="Global" value="global" />
          <Tab label="Code" value="code" />
          <Tab label="CAD" value="cad" />
          <Tab label="OMS" value="oms" />
          <Tab label="Incident" value="rms" />
          {/* <Tab label="Settings" value="settings" /> */}
          {/* <Tab label="Default" value="default" /> */}
          <Tab label="Create New" value="create" />
        </Tabs>
        <div role="tabpanel" className={classes.tabPanel}>
          <Box p={3}>
            {tabValue === 'applications' && group && <Applications role={group} />}
            {tabValue === 'global' && group && (
              <PermissionsComponent role={group} type="Core" title="Global" />
            )}
            {tabValue === 'code' && group && <Codes role={group} />}
            {tabValue === 'cad' && group && (
              <PermissionsComponent role={group} type="CAD" title="CAD" />
            )}
            {tabValue === 'oms' && group && (
              <PermissionsComponent role={group} type="OMS" title="OMS" />
            )}
            {tabValue === 'rms' && group && (
              <PermissionsComponent role={group} type="RMS" title="Incident" />
            )}
            {tabValue === 'settings' && group && (
              <PermissionsComponent role={group} type="Settings" title="Settings" />
            )}
            {/* {tabValue === 'default' && <DefaultPermissions />} */}
            {tabValue === 'create' && <CreateNewPermissions />}
          </Box>
        </div>
      </div>
    );
  };

  return (
    <>
      <Content>
        <div className="m-3">
          <h5>Roles</h5>
          {renderRoles()}
          {!group && tabValue !== 'default' && tabValue !== 'create' && (
            <p className={classes.msg}>Select the role to see permissions</p>
          )}
          <div className={classes.actions}>
            <Tooltip title="Add New Role" onClick={addSecRole}>
              <Fab size="small" color="secondary">
                <AddIcon />
              </Fab>
            </Tooltip>
            <Tooltip title="Edit Role" className="ml-2">
              <span>
                <Fab size="small" color="secondary" disabled={!group} onClick={editSecRole}>
                  <EditIcon />
                </Fab>
              </span>
            </Tooltip>
          </div>
          <h5 className="mt-4">Permissions</h5>
          <div className={classes.actions}>
            <Tooltip title="Add New Permission">
              <span>
                <Fab
                  size="small"
                  color="secondary"
                  onClick={() => setShowAddPermission(true)}
                  disabled={!addActive}>
                  <AddIcon />
                </Fab>
              </span>
            </Tooltip>
          </div>
          {renderTabs()}
        </div>
      </Content>
      {showAddPermission && (
        <AddPermission close={() => setShowAddPermission(false)} tabValue={tabValue} />
      )}
    </>
  );
}

const mapStateToProps = state => {
  return {
    dataUpdate: state.dataUpdate,
  };
};

export default connect(mapStateToProps, { showAddSecRole, showEditSecRole })(Permissions);
